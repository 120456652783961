<template>
  <div>
    <editor
      v-model="tinymceHtml"
      :id="id"
      :init="init"
      :key="tinymceFlag"
    ></editor>
    <Modal
      title="上传图片"
      ok-text="上传"
      :loading="true"
      v-model="showUploadImageModal"
      @on-ok="insertContent"
    >
      <i-upload fileKey="tinymce" multiple ref="UploadImage"></i-upload>
    </Modal>
  </div>
</template>

<script>
import IUpload from "@/components/upload/index.vue";
import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver/theme";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import * as Qiniu from "qiniu-js";
import dayjs from "dayjs";
import _ from "lodash";
import api from "@/api";
import "tinymce/icons/default/icons.min.js";

export default {
  name: "tinymce-editor",
  props: {
    id: String,
    value: {
      type: String,
      default: "",
    },
    plugins: {
      type: [String, Array],
      default: "image link code",
    },
    toolbar: {
      type: [String, Array],
      default:
        "bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat| uploadImage",
    },
  },
  data() {
    const self = this;
    return {
      images: [],
      showUploadImageModal: false,
      editor: null,
      tinymceFlag: 1,
      tinymceHtml: this.value,
      putExtra: {
        fname: "",
        params: {},
        mimeType: [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/webp",
          "video/mp4",
        ],
      },
      config: {
        useCdnDomain: true,
        region: Qiniu.region.z2,
      },
      init: {
        selector: `#${this.id}`,
        language_url: "libs/tinymce/zh_CN.js",
        language: "zh_CN",
        skin_url: "libs/tinymce/skins/ui/oxide",
        content_style: "body { font-size: 12px; }",
        fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
        height: 300,
        plugins: this.plugins,
        toolbar: this.toolbar,
        images_upload_handler: this.handleUpload,
        setup: function (editor) {
          editor.ui.registry.addButton("uploadImage", {
            text: "多图上传",
            icon: "image",
            onAction: () => {
              self.showUploadImageModal = true;
              self.editor = editor;
            },
          });
        },
      },
    };
  },
  watch: {
    value(newValue) {
      this.tinymceHtml = newValue;
    },
    tinymceHtml(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    async handleUpload(blobInfo, success, failure) {
      if (blobInfo.blob().size > 5120000) {
        failure("图片大于5M，请处理后在上传");
      } else {
        const file = blobInfo.blob();
        const key = `tinymce_${dayjs().format("YYYYMMDDHHMMSS")}_${_.random(
          999999
        )}${file.name.substr(file.name.lastIndexOf("."), file.name.length)}`;
        try {
          const res = await api.GetQiniuToken();
          const observable = Qiniu.upload(
            blobInfo.blob(),
            key,
            res.Data,
            this.putExtra,
            this.config
          );
          observable.subscribe(
            (res) => {
              console.log(`${key}:进度${res.total.percent}%`);
            },
            (err) => {
              this.$Notice.error({
                title: "错误",
                desc: err,
              });
              failure("图片上传失败");
              console.log(`上传失败: ${err}`);
            },
            (res) => {
              success(
                `${
                  process.env.VUE_APP_MODE === "production"
                    ? "https://static.ssdms.cn/"
                    : "https://static.ssdms.cn/"
                }${res.key}`
              );
            }
          );
        } catch (e) {
          this.$Notice.error({
            title: "错误",
            desc: e,
          });
        }
      }
    },
    async insertContent() {
      const res = await this.$refs.UploadImage.Upload();
      if (res.success.length !== 0) {
        let html = "";
        res.success.forEach((item) => {
          html += `<img src="${this.$config.baseUrl.imageServer}${item}"/>`;
        });
        this.editor.insertContent(html);
        this.showUploadImageModal = false;
        this.$refs.UploadImage.uploadFiles = [];
      } else {
        this.showUploadImageModal = false;
        this.$Message.error("图片上传失败");
      }
    },
  },
  activated() {
    this.tinymceFlag++;
  },
  mounted() {
    tinymce.init({ selector: `#${this.id}` });
  },
  computed: {
    token() {
      return this.$store.state.app.qiniuToken;
    },
  },
  components: { Editor, IUpload },
};
</script>

<style lang="scss"></style>
